<template>

  <div class="tvi-qdc">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col text-center p-0">
          <div class="tvi-qdc-banner d-flex justify-content-center align-items-center flex-md-row flex-column">
            <h1 class="text-md-end mt-5 mt-md-0 px-3 px-md-0">Vive la experiencia <span class="d-md-block">tras bambalinas del</span></h1><img :src="require('@/assets/galeria/logo_qdc.png')" alt="Logo Qué dice Chile" class="img-fluid" /></div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="tvi-qdc-contenedores">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="tvi-qdc-contenedores-boton text-center">
              <p>Click para enviar <span class="text-orange fw-bolder">DAMELO</span> al <span class="text-orange fw-bolder">5556</span></p>
              <a class="btn btn-qdc-textura fw-bold w-100" target="_blank" href="#" role="button" @click="enviarSMS">
                <p class="d-block">Participa X1</p><span>SMS | $590</span></a>
              <p class="mt-4">MÁS OPCIONES DE GANAR</p>
              <a class="btn btn-qdc-textura fw-bold w-100" target="_blank" @click="accionB2" href="https://www.mercadopago.cl/checkout/v1/redirect?pref_id=1380370301-3a1f5644-7df4-487b-8d95-4c639e5b5d9e" role="button">
                <p class="d-block">Participa X4</p><span>$1.990</span> </a>
              <a class="btn btn-qdc-naranjo fw-bold w-100 mt-4" target="_blank" @click="accionB3" href="https://www.mercadopago.cl/checkout/v1/redirect?pref_id=1380370301-d842c847-b007-4ad7-ba1b-8c481e14b649" role="button">
                <p class="d-block">Participa X10</p><span>$3.990</span></a>
            </div>
            <div class="row">
              <div class="col my-4 text-center pb-5"><a href="https://www.13.cl/concursos" target="_blank" class="link-dark" role="button">Bases del concurso</a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-black py-2 text-white">
        <div class="col text-center"><span>Desarrollado por </span><a href="https://www.celcomlatam.com/"><img srcset="" :src="require('@/assets/galeria/logo_celcom_blanco.svg')" alt="Logo Celcomlatam blanco" class="logo-celcom img-fluid"/></a></div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'TierraBravaUser',
  components: {

  },
  methods: {
    accionB2() {
      this.$gtag.event('Tb4', {
        'event_value': 'Tb4_B2'
      });
    },
    accionB3() {
      this.$gtag.event('Tb10', {
        'event_value': 'Tb10_B3'
      });
    },
    enviarSMS() {
      const ua = navigator.userAgent.toLowerCase();
      //const smsBody = 'Opcion'; // Cuerpo del mensaje

      var esAndroid = /Android/.test(navigator.userAgent);

      this.$gtag.event('click_SMS', {
        'event_value': 'DAMELO'
      });

      if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
        window.open(`sms://5556&body=DAMELO`, '_system');
      } else if (esAndroid) {
        var versionAndroid = parseFloat(navigator.userAgent.slice(navigator.userAgent.indexOf("Android") + 8));
        if (versionAndroid >= 4.4) {
          // A partir de Android 4.4 (KitKat), no se requiere el prefijo "//"
          window.open(`sms:5556?&body=DAMELO`, '_system');
        } else {
          // Para versiones anteriores a Android 4.4, agrega "//" antes del número de teléfono
          window.open(`sms://5556?&body=DAMELO`, '_system');
        }
      }
    },
  }
}
</script>