<template>


          <TierraBrava v-if="optParam === 'tierra-brava'"/>
          <C13 v-if="optParam === 'c13'"/>
          <Bunkers v-if="optParam === 'bunkers'"/>
          <QueDiceChile v-if="optParam === 'quedicechile'"/>
          <PanamericanosCHV v-if="optParam === 'panamericanos'"/>
          <BetanoCVH v-if="optParam === 'betano'"/>
          <Ganarc13 v-if="optParam === 'ganarc13'"/>
          <Copac13 v-if="optParam === 'copa'"/>
          <Got v-if="optParam === 'got'"/>
          <MiNombreEs v-if="optParam === 'mi-nombre-es'"/>

</template>

<script>
// @ is an alias to /src
import TierraBrava from "@/components/TierraBrava.vue";
import C13 from "@/components/C13.vue";
import Bunkers from "@/components/Bunkers.vue";
import Ganarc13 from "@/components/Ganarc13.vue";
import Copac13 from "@/components/Copac13.vue";
import PanamericanosCHV from "@/components/PanamericanosCHV.vue";
import BetanoCVH from "@/components/BetanoCVH.vue";
import Got from "@/components/Got/Got.vue";
import MiNombreEs from "@/components/MiNombreEs/MiNombreEs.vue";
import QueDiceChile from "@/components/QueDiceChile.vue";
export default {
  name: 'HomeView',
  components: {
    TierraBrava,
    PanamericanosCHV,
    C13,
    Ganarc13,
    Copac13,
    BetanoCVH,
    Got,
    QueDiceChile,
    Bunkers,
    MiNombreEs,
  },
  computed: {
    optParam() {
      return this.$route.params.optParam;
    }
  },
}
</script>
