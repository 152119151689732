<template>

  <div class="bg-tierra-brava">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 text-center p-0">




    <div class="tb-banner"><img srcset="" :src="require('@/assets/galeria/img_tb_main_desktop.jpg')" alt=" " class="d-none d-md-block img-fluid mx-auto" /> <img srcset="" :src="require('@/assets/galeria/img_tb_main.jpg')" alt=" " class="d-md-none img-fluid mx-auto" /></div>
    <div class="bg-azul">
      <div class="col text-center">
        <p class="text-white m-0">Participa por $1.000.000<br>A fin de mes</p>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 text-center p-0">
          <div class="tb-votacion-contenedores">
            <p class="text-white-50">Click para enviar SMS T al 5556</p>
            <div class="tb-votacion">
              <div class="tb-votacion-info"><a class="btn btn-gradiente-blue-cyan w-100" target="_blank" href="#" role="button" @click="enviarSMS"><span class="fw-bold d-block">Participa X1</span> <small>SMS | $590</small></a></div>
            </div>
            <hr class="text-white">
            <p class="text-white text-uppercase h5">Más opciones de ganar</p>
            <div class="tb-votacion">
              <div class="tb-votacion-info"><a class="btn btn-gradiente-blue-cyan w-100" target="_blank" @click="accionB2" href=" https://www.mercadopago.cl/checkout/v1/redirect?pref_id=1380370301-2084ea70-98f5-4816-8df0-bdbac92ebb84" role="button"><span class="fw-bold d-block">Participa X4</span> <small>$1.990</small></a></div>
            </div>
            <div class="tb-votacion">
              <div class="tb-votacion-info"><a class="btn btn-gradiente-gold w-100" target="_blank" @click="accionB3" href="https://www.mercadopago.cl/checkout/v1/redirect?pref_id=1380370301-8cefc067-6c3c-4489-a448-1e6350145a1b" role="button"><span class="fw-bold d-block">Participa X10</span> <small>$3.990</small> <span class="tb-emoji">🔥</span></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a href="https://www.13.cl/concursos" target="_blank" rel="noopener noreferrer" class="text-white d-block mb-5">Bases del concurso</a>


    <div class="bg-black text-white fixed-bottom">
      <div class="col text-center"><span>Desarrollado por </span><a href="https://www.celcomlatam.com/"><img srcset="" :src="require('@/assets/galeria/logo_celcom_blanco.svg')" alt="Logo Celcomlatam blanco" class="logo-celcom img-fluid"/></a></div>
    </div>
  </div>
</div>
</div>
</div>



</template>

<script>
// @ is an alias to /src

export default {
  name: 'TierraBravaUser',
  components: {

  },
  methods: {
    accionB2() {
      this.$gtag.event('Tb4', {
        'event_value': 'Tb4_B2'
      });
    },
    accionB3() {
      this.$gtag.event('Tb10', {
        'event_value': 'Tb10_B3'
      });
    },
    enviarSMS() {
      const ua = navigator.userAgent.toLowerCase();
      //const smsBody = 'Opcion'; // Cuerpo del mensaje

      var esAndroid = /Android/.test(navigator.userAgent);

      this.$gtag.event('click_SMS', {
        'event_value': 'TB_SMS'
      });

      if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
        window.open(`sms://5556&body=T`, '_system');
      } else if (esAndroid) {
        var versionAndroid = parseFloat(navigator.userAgent.slice(navigator.userAgent.indexOf("Android") + 8));
        if (versionAndroid >= 4.4) {
          // A partir de Android 4.4 (KitKat), no se requiere el prefijo "//"
          window.open(`sms:5556?&body=T`, '_system');
        } else {
          // Para versiones anteriores a Android 4.4, agrega "//" antes del número de teléfono
          window.open(`sms://5556?&body=T`, '_system');
        }
      }
    },
  }
}
</script>
