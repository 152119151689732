<template>
  <div :class="claseFondo">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center p-0">
          <div class="got-banner">
            <img
                srcset=""
                :src="require('../../assets/galeria/lnd_got_header_hq.png')"
                alt=" "
                class="img-fluid mx-auto"
            />
          </div>
          <div class="bg-black">
            <div class="col text-center"><p class="text-white m-0">Participa por $1.000.000 en la final</p></div>
          </div>
          <div  v-if="jsonData && jsonData.length && hayParticipantesActivos" :class="claseContenedor">
            <ParticipanteGot
                v-for="item in jsonData"
                :key="item.id"
                :dataNombre="item.nombre"
                :dataFoto="item.foto"
                :dataBoton1="item.boton1"
                :dataBoton2="item.boton2"
                :dataBoton3="item.boton3"
                :dataBoton4="item.boton4"
                :childValue="showModal"
                @changeValue="updateParentValue"/>
          </div>
          <div v-else-if="jsonData && !hayParticipantesActivos" class="got-votacion justify-content-center text-white text-uppercase">
            <h1 class="py-5 display-1">¡Votaciones Cerradas!</h1>
          </div>
          <div v-else class="gh-votacion justify-content-center text-white text-uppercase"/>
          <div class="bg-black text-white">
            <div class="col text-center">
              <a href="https://www.chilevision.cl/concursos" target="_blank" rel="noopener noreferrer" class="text-white d-block">
                Bases del concurso
              </a>
              <span>Desarrollado por </span>
              <a href="mailto:contacto@celcom.cl" target="_blank">
                <img
                    srcset=""
                    :src="require('../../assets/galeria/logo_celcom_blanco.svg')"
                    alt="Logo Celcomlatam blanco"
                    class="logo-celcom img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showModal" class="modal-votacion modal fade show">
        <ModalVotacionGot :dataNombre="nombre" :childValue="showModal" :dataBoton1="boton1" :dataBoton2="boton2" :dataBoton3="boton3" :dataBoton4="boton4" @changeValue="updateParentValue"/>
      </div>
    </div>
  </div>
</template>

<script>
import ParticipanteGot from "@/components/Got/ParticipanteGot.vue";
import ModalVotacionGot from "@/components/Got/ModalVotacionGot.vue";
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Got',
  components: {
    ParticipanteGot,
    ModalVotacionGot,
  },
  data() {
    return {
      cantidadActivos: null,
      showModal: false,
      nombre: '',
      foto: '',
      activo: false,
      boton1: '',
      boton2: '',
      boton3: '',
      boton4: '',
      jsonData: null,
      jsonLoaded: false
    };
  },
  computed: {
    hayParticipantesActivos() {
      return this.jsonData.some(item => item.activo);
    },
    claseFondo() {
      if (this.cantidadActivos <= 4) {
        return 'bg-dark got-landing bg-gradiente-azul-oscuro-a';
      } else {
        return 'bg-dark got-landing';
      }
    },
    claseContenedor() {
      if (this.cantidadActivos === 2) {
        return 'got-votacion-contenedores';
      } else {
        return 'got-votacion-contenedores';
      }
    },
  },
  methods: {
    cargarData() {
      axios.get("https://votaciongh.uc.r.appspot.com/participantes1")
          .then(response => {
            console.log('Se han cargado de forma correcta los Datos');
            this.jsonData = response.data.filter(item => item.activo);
            this.cantidadActivos = this.jsonData.filter(item => item.activo).length;
          })
          .catch(error => {
            console.error(error);
          });
      console.log(this.jsonData);
    },
    closeModal() {
      this.showModal = false;
    },
    updateParentValue(data) {
      this.showModal = data.modal;
      this.nombre = data.nombre;
      this.boton1 = data.boton1;
      this.boton2 = data.boton2;
      this.boton3 = data.boton3;
      this.boton4 = data.boton4;
    },
  },
  mounted() {
    this.cargarData();
  },
  created() {
  }
}
</script>
