<template>

  <div class="tvi-copa">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col text-center p-0">
          <div class="tvi-copa-banner">
            <div class="tvi-copa-banner-title">
              <h2>PARTICIPA POR $500.000<br>EN LA FINAL DE LA COPA AMÉRICA</h2>
            </div><img srcset="" :src="require('@/assets/galeria/header_tvi_copa.jpg')" alt=" " class="d-none d-md-block img-fluid mx-auto" /> <img srcset="" :src="require('@/assets/galeria/header_tvi_copa_mobile.jpg')" alt=" " class="d-md-none img-fluid mx-auto" /></div>
          <div class="tvi-copa-contenedores">
            <div class="row justify-content-center">
              <div class="col-md-8 col-10">
                <div class="tvi-copa-contenedores-boton">
                  <p>Click para enviar GOL al 5556</p>
                  <a class="btn btn-rojo-azul w-100" target="_blank" href="#" role="button" @click="enviarSMS">
                    <p class="fw-bold d-block">Participa X1</p><span>SMS | $590</span></a>
                </div>
                <div class="tvi-copa-contenedores-boton pt-3">
                  <p class="text-gray text-uppercase">Más opciones de ganar</p>
                  <a class="btn btn-rojo-azul mb-2 w-100" target="_blank" href="https://www.mercadopago.cl/checkout/v1/redirect?pref_id=1380370301-70afd3b2-2ba3-424d-9003-b36e007d7002" role="button" @click="accionB2">
                    <p class="fw-bold d-block">Participa X4</p><span>$1.990</span> </a>
                  <a class="btn btn-azul-rojo w-100" target="_blank" href="https://www.mercadopago.cl/checkout/v1/redirect?pref_id=1380370301-c262187b-b492-4923-9502-76a15b87c122" role="button" @click="accionB3">
                    <p class="fw-bold d-block">Participa X10</p><span>$3.990</span></a>
                </div>
                <div class="row">
                  <div class="col mt-4 mb-5"><a href="https://www.13.cl/concursos" class="link-dark" role="button">Bases del concurso</a></div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-black py-2 text-white">
            <div class="col text-center"><span>Desarrollado por </span><a href="https://www.celcomlatam.com/"><img srcset="" :src="require('@/assets/galeria/logo_celcom_blanco.svg')" alt="Logo Celcomlatam blanco" class="logo-celcom img-fluid"/></a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'GanarC13',
  components: {

  },
  methods: {
    accionB2() {
      this.$gtag.event('Tb4', {
        'event_value': 'Tb4_B2'
      });
    },
    accionB3() {
      this.$gtag.event('Tb10', {
        'event_value': 'Tb10_B3'
      });
    },
    enviarSMS() {
      const ua = navigator.userAgent.toLowerCase();
      //const smsBody = 'Opcion'; // Cuerpo del mensaje

      var esAndroid = /Android/.test(navigator.userAgent);

      this.$gtag.event('click_SMS', {
        'event_value': 'GOL'
      });

      if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
        window.open(`sms://5556&body=GOL`, '_system');
      } else if (esAndroid) {
        var versionAndroid = parseFloat(navigator.userAgent.slice(navigator.userAgent.indexOf("Android") + 8));
        if (versionAndroid >= 4.4) {
          // A partir de Android 4.4 (KitKat), no se requiere el prefijo "//"
          window.open(`sms:5556?&body=GOL`, '_system');
        } else {
          // Para versiones anteriores a Android 4.4, agrega "//" antes del número de teléfono
          window.open(`sms://5556?&body=GOL`, '_system');
        }
      }
    },
  }
}
</script>