<template>
  <div :class="claseFondo">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center p-0">
          <div class="got-banner">
            <img
                srcset=""
                :src="require('../../assets/galeria/LOGO_FONDO_LUCES.jpg')"
                alt=" "
                class="img-fluid mx-auto"
            />
          </div>
          <div class="bg-black">
           <span class="tvi-qdc-texto">¿Quién quieres ver de regreso?</span>
            <div class="col text-center"><p class="text-white m-0">Participa por $300.000 esta semana</p></div>
          </div>
          <div  v-if="jsonData && jsonData.length && hayParticipantesActivos" :class="claseContenedor">
            <ParticipanteMiNombreEs
                v-for="item in jsonData"
                :key="item.participantesGeneralId"
                :dataNombre="item.nombre"
                :dataFoto="item.imageLink"
                :dataBoton1="item.buttonSms"
                :dataBoton2="item.buttonMpFirst"
                :dataBoton3="item.buttonMpSecond"
                :dataBoton4="item.buttonMpThird"
                :childValue="showModal"
                @changeValue="updateParentValue"/>
          </div>
          <div v-if="jsonDataAnterior && jsonDataAnterior.length && hayParticipantesActivos" class="got-votacion justify-content-center text-white text-uppercase">
            <div>
              <!-- Iterar sobre las categorías agrupadas -->
              <div v-for="(participantes, categoria) in agrupadosPorCategoria" :key="categoria" class="accordion-item">
                <h2 class="accordion-header" @click="toggleSection(categoria)">
                  <button
                      :class="['accordion-button', { 'expanded': activeSection === categoria }]"
                      type="button"
                  >
                    {{ categoria ? categoria : 'Sin categoría' }} <!-- Mostrar la categoría -->
                  </button>
                </h2>
                <transition name="slide-fade">
                  <div v-if="activeSection === categoria"  class="accordion-collapse collapse show">
                    <div class="accordion-body">
                      <ParticipanteMiNombreEs
                          v-for="item in participantes"
                          :key="item.participantesGeneralId"
                          :dataNombre="item.nombre"
                          :dataFoto="item.imageLink"
                          :dataBoton1="item.buttonSms"
                          :dataBoton2="item.buttonMpFirst"
                          :dataBoton3="item.buttonMpSecond"
                          :dataBoton4="item.buttonMpThird"
                          :childValue="showModal"
                          @changeValue="updateParentValue"/>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <div v-else-if="jsonData && !hayParticipantesActivos" class="got-votacion justify-content-center text-white text-uppercase">
            <h1 class="py-5 display-1">¡Votaciones Cerradas!</h1>
          </div>
          <div v-else class="gh-votacion justify-content-center text-white text-uppercase"/>
          <div class="bg-black text-white">
            <div class="col text-center">
              <a href="https://www.tvn.cl/programas/mi-nombre-es/destacados/revisa-las-bases-de-mi-nombre-es" target="_blank" rel="noopener noreferrer" class="text-white d-block">
                Bases del concurso
              </a>
              <span>Desarrollado por </span>
              <a href="mailto:contacto@celcom.cl" target="_blank">
                <img
                    srcset=""
                    :src="require('../../assets/galeria/logo_celcom_blanco.svg')"
                    alt="Logo Celcomlatam blanco"
                    class="logo-celcom img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showModal" class="modal-votacion modal fade show">
        <ModalVotacionMiNombreEs :dataNombre="nombre" :childValue="showModal" :dataBoton1="boton1" :dataBoton2="boton2" :dataBoton3="boton3" :dataBoton4="boton4" @changeValue="updateParentValue"/>
      </div>
    </div>
  </div>
</template>

<script>
import ParticipanteMiNombreEs from "@/components/MiNombreEs/ParticipanteMiNombreEs.vue";
import ModalVotacionMiNombreEs from "@/components/MiNombreEs/ModalVotacionMiNombreEs.vue";
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'MiNombreEs',
  components: {
    ParticipanteMiNombreEs,
    ModalVotacionMiNombreEs,
  },
  data() {
    return {
      activeSection: null, // Para controlar qué sección está abierta
      cantidadActivos: null,
      showModal: false,
      nombre: '',
      foto: '',
      activo: false,
      boton1: '',
      boton2: '',
      boton3: '',
      boton4: '',
      jsonData: null,
      jsonDataAnterior: null,
      jsonLoaded: false
    };
  },
  computed: {
    agrupadosPorCategoria() {
      // Reducimos el array en un objeto categorizado
      const categoriasAgrupadas = this.jsonDataAnterior.reduce((acc, participante) => {
        const categoria = participante.category || 'Sin categoría';
        if (!acc[categoria]) {
          acc[categoria] = [];
        }
        acc[categoria].push(participante);
        return acc;
      }, {});

      // Ordenamos las claves en orden inverso (descendente)
      const categoriasOrdenadas = Object.keys(categoriasAgrupadas)
          .sort((a, b) => {
            const numA = parseInt(a.match(/\d+/)); // Extrae el número de la semana
            const numB = parseInt(b.match(/\d+/));
            return numB - numA; // Ordena de mayor a menor
          })
          .reduce((acc, key) => {
            acc[key] = categoriasAgrupadas[key];
            return acc;
          }, {});

      return categoriasOrdenadas;
    },
    hayParticipantesActivos() {
      return this.jsonData.some(item => item.active);
    },
    claseFondo() {
      if (this.cantidadActivos <= 4) {
        return 'bg-dark got-landing';
      } else {
        return 'bg-dark got-landing';
      }
    },
    claseContenedor() {
      if (this.cantidadActivos === 2) {
        return 'got-votacion-contenedores grid-t-c-2';
      } else {
        return 'got-votacion-contenedores grid-t-c-2';
      }
    },
  },
  methods: {
    toggleSection(categoria) {
      this.activeSection = this.activeSection === categoria ? null : categoria;
    },
    cargarData() {
      axios.get("https://votaciongh.uc.r.appspot.com/participantes_general/lp/1")
      //axios.get("http://192.168.1.19:3113/participantes1")
          .then(response => {
            console.log('Se han cargado de forma correcta los Datos');
            this.jsonData = response.data.filter(item => item.active && item.category === '');
            this.jsonDataAnterior = response.data.filter(item => item.active && item.category !== '');
            this.cantidadActivos = this.jsonData.filter(item => item.active).length;
          })
          .catch(error => {
            console.error(error);
          });
      console.log(this.jsonData);
    },
    closeModal() {
      this.showModal = false;
    },
    updateParentValue(data) {
      this.showModal = data.modal;
      this.nombre = data.nombre;
      this.boton1 = data.boton1;
      this.boton2 = data.boton2;
      this.boton3 = data.boton3;
      this.boton4 = data.boton4;
    },
  },
  mounted() {
    this.cargarData();
  },
}
</script>
<style scoped>
.accordion-body {
  padding: 15px;
  background-color: #ee8a42;
  border: 0;
}
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-from, .slide-fade-leave-to /* .slide-fade-leave-active en Vue 2 */ {
  transform: translateY(-10px);
  opacity: 0;
}
.switch-url.form-switch .form-check-input:after {
  width: 1rem !important;
  height: 1rem !important;
}
.got-landing .accordion-item {
  padding: 1rem;
  border-bottom: 1px solid #FFF;
}
.got-landing .accordion-item:last-of-type {
  border: 0;
}
.accordion-button:hover {
  background-color: #ee8a42;
}
.got-landing .got-votacion {
  background-color: #000;
}
.got-landing .accordion-item .accordion-button {
  color: #f39833;
}
.got-landing .accordion-button::after {
  content: " ";
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23f39833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
}

.got-landing .accordion-button.expanded::after {
  content: " ";
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 15L12 9L18 15' stroke='%23f39833' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
}
</style>
