import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag';

// Configura vue-gtag antes de crear la aplicación Vue
const app = createApp(App);

// Configuración de vue-gtag
app.use(VueGtag, {
    config: { id: 'G-C1ZGRXGH41' },
});

// Usa tus otros plugins (store y router)
app.use(store);
app.use(router);

// Monta la aplicación Vue
app.mount('#app');
