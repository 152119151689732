<template>
  <div class="modal-votacion">
    <div class="modal-header border-0"><button type="button" @click="closeModal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
    <div class="modal-body text-center text-white">
      <p class="m-0">Vota por:</p>
      <h1>{{ dataNombre }}</h1>
      <span class="text-secondary"><small>Click para enviar SMS {{ dataNombre }} al 4490</small> </span><a class="btn btn-gradiente-morado w-100" href="#" @click="enviarSMS" role="button">1 VOTO <small>SMS | $590</small></a>
    </div>
    <div class="modal-footer">
      <a class="btn btn-gradiente-morado w-100" @click="accionB4" :href="dataBoton4" role="button">5 VOTOS <small>$1990</small> </a>
      <a class="btn btn-gradiente-morado w-100" @click="accionB2" :href="dataBoton2" role="button">10 VOTOS <small>$3990</small> <span class="gh-emoji">🔥</span></a>
<!--      <a class="btn btn-gradiente-votos w-100" @click="accionB3" :href="dataBoton3" role="button">20 VOTOS <small>$6990</small> <span class="gh-emoji">🔥</span></a>-->
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'dataNombre',
    'dataBoton1',
    'dataBoton2',
    'dataBoton3',
    'dataBoton4'
  ],
  methods: {
    closeModal() {
      this.$emit('changeValue', false);
    },
    accionB2() {
      this.$gtag.event('mp10_'+this.dataNombre, {
        'event_value': this.dataNombre
      });
    },
    accionB3() {
      this.$gtag.event('mp20_'+this.dataNombre, {
        'event_value': this.dataNombre
      });
    },
    accionB4() {
      this.$gtag.event('mp5_'+this.dataNombre, {
        'event_value': this.dataNombre
      });
    },
    enviarSMS() {
      const ua = navigator.userAgent.toLowerCase();
      //const smsBody = 'Opcion'; // Cuerpo del mensaje

      var esAndroid = /Android/.test(navigator.userAgent);

      this.$gtag.event('click_SMS_'+this.dataNombre, {
        'event_value': this.dataNombre
      });

      if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
        window.open(`sms://4490&body=${this.dataNombre}`, '_system');
      } else if (esAndroid) {
        var versionAndroid = parseFloat(navigator.userAgent.slice(navigator.userAgent.indexOf("Android") + 8));
        if (versionAndroid >= 4.4) {
          // A partir de Android 4.4 (KitKat), no se requiere el prefijo "//"
          window.open(`sms:4490?&body=${this.dataNombre}`, '_system');
        } else {
          // Para versiones anteriores a Android 4.4, agrega "//" antes del número de teléfono
          window.open(`sms://4490?&body=${this.dataNombre}`, '_system');
        }
      }
    },
  },
};
</script>