<template>
  <div class="bg-panamericanos">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 text-center p-0">


          
    <div class="tb-banner"><img srcset="" :src="require('@/assets/galeria/img_panamericanos_banner_hd.png')" alt=" " class="d-none d-md-block img-fluid mx-auto" />
       <img srcset="" :src="require('@/assets/galeria/img_panamericanos_banner.png')" alt=" " class="d-md-none img-fluid mx-auto" /></div>
    
    <div class="bg-amarillo">
      <div class="col text-center">
          <h5 class="text-dorado">¿Cúantos jugadores<br>
              tiene un equipo de fútbol?</h5>
          <hr class="text-dorado">
          <h3 class="text-azul">RESPONDE LA TRIVIA Y<br>
              Participa por $1.000.000</h3>
      </div>
  </div>
  <div class="panamericanos-votacion-contenedores">
  
      <p class="text-white text-sombra">Click para enviar SMS JUEGOS al 4490</p>
  
      <div class="tb-votacion">
          <div class="tb-votacion-info">
            <a class="btn btn-gradiente-red-blue w-100" target="_blank" href="#" role="button" @click="enviarSMS">
              <h3 class="fw-bold d-block">Comienza a jugar</h3>
<span>SMS | $590</span>
              </a>
          </div>
      </div>
  
      <hr class="text-white">
  
      <a href="https://www.chilevision.cl/concursos" target="_blank" rel="noopener noreferrer" class="text-white d-block mb-5">Bases del concurso</a>
  
  </div>



    <div class="bg-black text-white fixed-bottom">
      <div class="col text-center"><span>Desarrollado por </span><a href="https://www.celcomlatam.com/"><img srcset="" :src="require('@/assets/galeria/logo_celcom_blanco.svg')" alt="Logo Celcomlatam blanco" class="logo-celcom img-fluid"/></a></div>
    </div>
  </div>
</div>
</div>
</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'TierraBravaUser',
  components: {

  },
  methods: {
    enviarSMS() {
      const ua = navigator.userAgent.toLowerCase();
      //const smsBody = 'Opcion'; // Cuerpo del mensaje

      var esAndroid = /Android/.test(navigator.userAgent);

     /* this.$gtag.event('click_SMS_'+this.dataNombre, {
        'event_value': this.dataNombre
      });*/

      if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
        window.open(`sms://4490&body=JUEGOS`, '_system');
      } else if (esAndroid) {
        var versionAndroid = parseFloat(navigator.userAgent.slice(navigator.userAgent.indexOf("Android") + 8));
        if (versionAndroid >= 4.4) {
          // A partir de Android 4.4 (KitKat), no se requiere el prefijo "//"
          window.open(`sms:4490?&body=JUEGOS`, '_system');
        } else {
          // Para versiones anteriores a Android 4.4, agrega "//" antes del número de teléfono
          window.open(`sms://4490?&body=JUEGOS`, '_system');
        }
      }
    },
    
  }
}
</script>
