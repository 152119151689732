<template>
  <div class="got-votacion">
    <img srcset="" :src="validFoto" :alt="altText" class="got-votacion-img img-fluid cursor-pointer" @click="llamarModal" />
    <div class="got-votacion-info">
      <a class="btn btn-gradiente-gold w-100" href="#" role="button" @click="llamarModal" data-bs-toggle="modal" data-bs-target="#votaGH1">{{ dataNombre }}</a>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'dataNombre',
    'dataFoto',
    'dataBoton1',
    'dataBoton2',
    'dataBoton3',
    'dataBoton4'
  ],
  computed: {
    validFoto() {
      try {
        //return require(`@/assets/galeria/${this.dataFoto}`);
        return this.dataFoto;
      } catch (error) {
        // Si la URL de la imagen no es válida, retorna una imagen alternativa
        return require('../../assets/galeria/lnd_got_header_hq.png'); // Cambia "default.jpg" por la imagen alternativa deseada
      }
    },
    altText() {
      return this.dataFoto ? `Foto de ${this.dataNombre}` : 'Foto del Participante';
    }
  },
  methods: {
    llamarModal() {
      this.$emit('changeValue', {
        modal: true,
        nombre: this.dataNombre,
        boton1: this.dataBoton1,
        boton2: this.dataBoton2,
        boton3: this.dataBoton3,
        boton4: this.dataBoton4,
      });

    },
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>