<template>
  <div class="tvi-chv-betano">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col text-center p-0">
          <div class="tvi-chv-betano-banner">
            <div class="tvi-chv-betano-banner-title">
              <h1 class="mt-5 mb-mb-0 mb-4">PARTICIPA POR UN<br>VIAJE TODO PAGO</h1>
            </div>
            <div class="d-flex align-items-center pb-md-5 px-3">
              <div class="col"><img :src="require('@/assets/galeria/logo_chv.svg')" alt="Logo Chilevision" class="d-none d-md-block img-fluid w-100" /></div>
              <div class="col-12 col-md-8">
                <h2 class="px-2">PARA LA COPA AMÉRICA 2024</h2>
              </div>
              <div class="col"><img :src="require('@/assets/galeria/logo_betano.svg')" alt="Logo Betano" class="d-none d-md-block img-fluid w-100" /></div>
            </div>
            <div class="d-flex flex-row align-items-center d-md-none pb-4">
              <div class="p-3 w-50 border-end border-white"><img :src="require('@/assets/galeria/logo_chv.svg')" alt="Logo Chilevision" class="img-fluid" /></div>
              <div class="p-3 w-50"><img :src="require('@/assets/galeria/logo_betano.svg')" alt="Logo Betano" class="img-fluid" /></div>
            </div>
            <h4>Participa hasta el 03 de junio</h4>
          </div>
          <div class="tvi-chv-betano-contenedores">
            <div class="row justify-content-center">
              <div class="col-md-6">
                <div class="tvi-chv-betano-contenedores-boton">
                  <p>Click para enviar <span class="text-orange fw-bolder">COPA</span> al <span class="text-orange fw-bolder">4490</span></p>
                  <a class="btn btn-textura w-100" target="_blank" href="#" role="button" @click="enviarSMS">
                    <p class="fw-bold d-block">Participa</p><span>SMS | $590</span></a>
                </div>
                <div class="row">
                  <div class="col my-4"><a href="https://www.chilevision.cl/concursos" class="link-dark" role="button">Bases del concurso</a></div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-black py-2 text-white">
            <div class="col text-center"><span>Desarrollado por </span><a href="https://www.celcomlatam.com/"><img srcset="" :src="require('@/assets/galeria/logo_celcom_blanco.svg')" alt="Logo Celcomlatam blanco" class="logo-celcom img-fluid"/></a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'TierraBravaUser',
  components: {

  },
  methods: {
    enviarSMS() {
      const ua = navigator.userAgent.toLowerCase();
      //const smsBody = 'Opcion'; // Cuerpo del mensaje

      var esAndroid = /Android/.test(navigator.userAgent);

     /* this.$gtag.event('click_SMS_'+this.dataNombre, {
        'event_value': this.dataNombre
      });*/

      if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
        window.open(`sms://4490&body=COPA`, '_system');
      } else if (esAndroid) {
        var versionAndroid = parseFloat(navigator.userAgent.slice(navigator.userAgent.indexOf("Android") + 8));
        if (versionAndroid >= 4.4) {
          // A partir de Android 4.4 (KitKat), no se requiere el prefijo "//"
          window.open(`sms:4490?&body=COPA`, '_system');
        } else {
          // Para versiones anteriores a Android 4.4, agrega "//" antes del número de teléfono
          window.open(`sms://4490?&body=COPA`, '_system');
        }
      }
    },
    
  }
}
</script>
