<template>
        <div :class="clasetext">
          <h1 >{{ Mensaje }}</h1>
<!--          <a class="btn btn-gradiente-cyan" @click="closeModal" role="button">Volver</a>-->
        </div>
</template>

<script>
export default {
  name: 'MensajeTrivia',
  props: {
    clasetext: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      Mensaje: "Inténtalo nuevamente, tienes una nueva oportunidad de contestar de manera correcta",
    };
  },
  methods: {
    closeModal() {
      this.$emit('changeValue', {
        triviaStatus: true,
        modal: false,
      });
    },
  },
};
</script>
